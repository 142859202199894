<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card  grid-list-md>
      <ModelTitle title="New Lead" @close="close()" />
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              outlined
              :hide-details="nameErrors.length === 0"
              dense @change="Lead.name = properCase(Lead.name)"
              :error-messages="nameErrors"
              v-model="Lead.name"
              label="Business Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              outlined @change="Lead.contactName = properCase(Lead.contactName)"
              dense
              hide-details
              v-model="Lead.contactName"
              label="Contact Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="Lead.phoneNumber"
              label="Phone Number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="Lead.email"
              label="Email Address"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-select
              dense
              outlined
              :items="leadStatus"
              v-model="Lead.status"
              label="Status"
              item-text="name"
              hide-details
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12">
            <v-text-field
              outlined
              hide-details
              dense
              v-model="Lead.address"
              label="Address"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              outlined
              hide-details
              dense @change="Lead.city = properCase(Lead.city)"
              v-model="Lead.city"
              label="City"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="Lead.state"
              label="State"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="Lead.zip"
              label="Zip Code"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12">
            <v-textarea
              outlined
              dense
              hide-details
              auto-grow
              v-model="Lead.note"
              :label="$t('labels.note')"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close()">Close</v-btn>
        <v-btn color="blue darken-1" :loading="loading" dark @click="submit()"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import LeadService from "../service.js";
import GlobalFunction from "@/mixins/GlobalFunction";

const { validationMixin } = require("vuelidate");
const { required } = require("vuelidate/lib/validators");

export default {
  name: "create-Lead",
  props: {
    createDialog: {
      default: false
    },
    createFromStore: {
      type: Boolean
    }
  },
  data() {
    return {
      leadStatus: ["Hot Lead", "Cold", "Dead", "Convert", "Warm Lead"],
      loading: false,
      dialog: false,
      Lead: {
        name: null,
        email: null,
        phoneNumber: null,
        contactName: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        note: null,
        status: 'Hot Lead',
      }
    };
  },
  mixins: [validationMixin, GlobalFunction],
  validations: {
    Lead: {
      name: {
        required
      }
    }
  },
  watch: {
    createDialog(val) {
      this.dialog = val;
    }
  },
  computed: {
    ...mapGetters("global", ["currentUser"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.Lead.name.$dirty) return errors;
      if (!this.$v.Lead.name.required)
        errors.push(this.$t("validations.fieldIsRequired"));

      return errors;
    }
  },
  methods: {
    close() {
      this.empty();
      this.$emit("close");
    },
    empty() {
      this.Lead = {
        name: null,
        email: null,
        phoneNumber: null,
        contactName: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        note: null,
        status: 'Hot Lead',
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields")
          });
      } else {
        this.loading = true;
        return LeadService.create(this.Lead)
          .then(result => {
            if (result.status) {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000
                })
                .fire({
                  icon: "success",
                  title: "Lead is created"
                });
              this.loading = false;
              this.$emit("submit");
              this.close();
            }
          })
          .catch(err => {
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000
              })
              .fire({
                icon: "error",
                title: err.data.message
              });
          });
      }
    }
  }
};
</script>

<style scoped></style>
